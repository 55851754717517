import { useEffect, useState } from 'react';

import { setLocaleToCookie, t } from '@/utility/localization';
import {
  CM_PORTAL_HOMEPAGE_ROUTE,
  redirectSafelyOnAllBrowsers
} from '@/utility/routesHelper';

import { useAuthContext } from '@/contexts/AuthContext';

import Icon from '@/components/npl/Icon';

import useQueryParams from '@/hooks/useQueryParams';

import { sendLoginDataToAnalytics } from '../../utils';
import EmailForm from './EmailForm';
import OtpForm from './OtpForm';
import PasswordCreationForm from './PasswordCreationForm';
import PasswordForm from './PasswordForm';
import { LOGIN_FORM_STATES } from './constants';

const FormComponent = ({ formState, ...props }) => {
  let StatedComponent = EmailForm;
  switch (formState.current) {
    case LOGIN_FORM_STATES.EMAIL_FORM:
      StatedComponent = EmailForm;
      break;
    case LOGIN_FORM_STATES.PASSWORD_FORM:
      StatedComponent = PasswordForm;
      break;
    case LOGIN_FORM_STATES.OTP_FORM:
      StatedComponent = OtpForm;
      break;
    case LOGIN_FORM_STATES.PASSWORD_CREATION_FORM:
      StatedComponent = PasswordCreationForm;
      break;
  }
  return <StatedComponent {...props}></StatedComponent>;
};

const LoginForm = () => {
  const { currentRouterQuery } = useQueryParams();

  const {
    isDemoUser,
    user,
    redirectMemberToCommunityPage,
    isIntentionalLogin
  } = useAuthContext();

  const [formState, setFormState] = useState({
    current: LOGIN_FORM_STATES.EMAIL_FORM
  });
  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
    otpToken: '',
    otpCode: '',
    otpError: ''
  });

  const redirectTo = currentRouterQuery?.redirectTo;

  useEffect(() => {
    if (currentRouterQuery?.email) {
      setFormValues((prev) => ({
        ...prev,
        email: currentRouterQuery.email
      }));
    }
  }, [currentRouterQuery?.email]);

  const postLogin = (userData) => {
    if (userData?.learner?.languagePreference) {
      setLocaleToCookie(userData.learner.languagePreference.toLowerCase());
    }

    sendLoginDataToAnalytics(userData);
    // if redirectTo is set in query params, redirect to user to that link after login.
    if (redirectTo) {
      redirectSafelyOnAllBrowsers(redirectTo);
      return;
    }

    // if user is a CM, then redirect to CM portal.
    if (
      userData?.roles?.owner ||
      userData?.roles?.admin ||
      userData?.roles?.manager
    ) {
      redirectSafelyOnAllBrowsers(CM_PORTAL_HOMEPAGE_ROUTE);
      return;
    }
    // if user is just a member, then redirect to member portal.
    redirectMemberToCommunityPage({ isFullReload: true });
  };

  const headingTextMap = {
    [LOGIN_FORM_STATES.EMAIL_FORM]: t('login-to-your-community'),
    [LOGIN_FORM_STATES.PASSWORD_FORM]: t('welcome-back-please-log-in'),
    [LOGIN_FORM_STATES.OTP_FORM]: t('welcome-back-enter-code-to-co'),
    [LOGIN_FORM_STATES.PASSWORD_CREATION_FORM]: t('create-password')
  };

  // Handle logged in user visiting login page.
  useEffect(() => {
    // Need to have user object
    // Not demo user
    // Not logging in intentionally as it will be triggered in the login function
    if (user && !isDemoUser && !isIntentionalLogin) {
      postLogin(user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDemoUser, user, isIntentionalLogin]);

  const title = formState?.current
    ? headingTextMap[formState.current]
    : t('login-to-your-community');

  return (
    <div className="flex flex-col space-y-24">
      <Icon
        name="nas-io-logo-coloured"
        path="logo"
        width={32}
        height={32}
      />
      <p className="text-heading-sm font-semibold text-dark-neutral10 xl:mt-32 xl:text-heading-md">
        {title}
      </p>
      <FormComponent
        postLogin={postLogin}
        setFormValues={setFormValues}
        formValues={formValues}
        setFormState={setFormState}
        formState={formState}
      />
      <div className="text-center">
        <span className="text-body-xs text-light-text lg:text-label-sm">
          {t('by-continuing-you-agree-with-nas-ios')}
          <a
            href="/community-manager-terms"
            target="__blank"
            className="underline ml-4 text-npl-text-primary-on-light font-medium">
            {t('terms-and-conditions')}
          </a>
        </span>
      </div>
    </div>
  );
};

export default LoginForm;
